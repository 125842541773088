import firebase from 'firebase/compat/app';

import { EventItemGuest, EventJoinerStatus, EventRsvpStatus } from '../interfaces/event-item';
import { DBModel } from './model.db';

export class EventJoinerModel extends DBModel {
  static readonly PLAYER = 'player';
  static readonly STATUS = 'status';
  static readonly ASSIGNED_GENDER_SPOT = 'assignedGenderSpot';
  static readonly APPROVED_BY = 'approvedBy';
  static readonly GUESTS = 'guests';
  static readonly RSVP_STATUS = 'rsvpStatus';
  static readonly CREATED_AT = 'createdAt';
  static readonly IS_GUEST_USER = 'isGuestUser';
  static readonly IS_UNREGISTERED_USER = 'isUnregisteredUser';
  static readonly SLOT = 'slotName';
  static readonly SLOT_NUMBER = 'slotNumber';
  static readonly GUEST_OF_PARTICIPANT = 'guestOfParticipant';
  static readonly SURVEY_ANSWERS = 'surveyAnswers';
  static readonly EVENT_TEAMS_ONLY = 'eventTeamsOnly';
  static readonly GUEST_NAME = 'guestName';
  static readonly GUEST_EMAIL = 'guestEmail';
  static readonly GROUP_NAME = 'groupName';

  constructor(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    public readonly player: string,
    public status?: EventJoinerStatus,
    public assignedGenderSpot?: string,
    public readonly approvedBy: string[] = [],
    public readonly guests: EventItemGuest[] = [],
    public readonly rsvpStatus: EventRsvpStatus = EventRsvpStatus.NOT_PLAYING,
    public readonly createdAt = firebase.firestore.Timestamp,
    public readonly isGuestUser = false,
    public readonly isUnregisteredUser = false,
    public readonly slotName = '',
    public readonly slotNumber = 0,
    public readonly guestOfParticipant = false,
    public readonly surveyAnswers:any[]= [],
    public readonly eventTeamsOnly?:string,
    public readonly guestName?: string,
    public readonly guestEmail?: string,
    public readonly groupName?: string,
  ) {
    super(ref);
  }
}
