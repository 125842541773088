import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventJoiner } from '@index/interfaces';
import { EventJoinerModel } from '@index/models/event-joiner';
import { GthUserModel } from '@sentinels/models';
import { UserService } from '@sentinels/services/firebase/user.service';
import { combineLatest, from, mergeMap, Observable, of, switchMap, toArray } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class JoinerService extends FirestoreService<EventJoinerModel> {
  protected basePath: string = 'events';

  constructor(
    firestore: AngularFirestore,
    private userService: UserService,
  ) {
    super(firestore);
  }

  getJoiners(eventId: string):Observable<(EventJoinerModel & { user?: GthUserModel })[]> {
    return this.doc(eventId).collection<EventJoiner>('joiner')
      .valueChanges({ idField: 'id' })
      .pipe(
        map((joiners) => {
          // If joiners array is empty, emit an empty array
          if (!joiners || joiners.length === 0) {
            return [];
          }
          return joiners as EventJoinerModel[];
        }),
        switchMap((joiners) => {
          return from(joiners).pipe(
            mergeMap((joiner: EventJoinerModel & { user?: GthUserModel }) => {
              return combineLatest([
                of(joiner),
                this.userService.getUser$(joiner.player),
              ]).pipe(
                map(([joiner, user]) => {
                  joiner.user = user;
                  return joiner;
                }),
              );
            }),
            take(joiners.length),
            toArray(),
          );
        }),
        startWith([]), // Emit an empty array as the first item
      );
  }

  updateJoiner(uid: string, data: any) {
    return this.update(uid, data);
  }
}
