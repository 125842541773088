import { inject, Injectable } from '@angular/core';
import { DocumentChangeAction, DocumentData } from '@angular/fire/compat/firestore';
import { GetEventPageViewContract, UpdateEventPageViewContract, UpdateResponse } from '@index/daos/event-item-dao';
import { EventListFactory } from '@index/daos/utils/event-list-factory';
import { EventItem, EventItemDeleteRequest, UpdateEventItemRequest } from '@index/interfaces';
import { EventItemListFilter } from '@index/interfaces/event-list-filter';
import { EventItemMapper } from '@index/mappers/event-item-mapper';
import { EventItemModel } from '@index/models/event-item';
import { EventJoinerModel } from '@index/models/event-joiner';
import { DBUtil } from '@index/utils/db-utils';
import { GthEventItemModel } from '@sentinels/models';
import firebase from 'firebase/compat/app';
import { distanceBetween } from 'geofire-common';
import { combineLatest, from, lastValueFrom, Observable, of, zip } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';
import { JoinerService } from './joiner.service';

@Injectable({ providedIn: 'root' })
export class EventItemService extends FirestoreService<EventItem> {
  private joinersService = inject(JoinerService);

  private mapper = new EventItemMapper();
  private eventListFactory = new EventListFactory(this.firestore as any);

  protected basePath: string = 'events';
  private readonly eventPageViewShards = 5;

  /**
   * Gets event by ID.
   * @param {eventId} eventId
   * @return {Observable<GthEventItemModel>}
   */
  getEvent(eventId: string): Observable<GthEventItemModel | null> {
    return this.doc<EventItem>(eventId).snapshotChanges().pipe(
      map((doc) => {
        const data = doc.payload.data();
        if (data.dateStart && (data.dateStart as any).toDate) {
          data.dateStart = (data.dateStart as any).toDate();
        }

        return new GthEventItemModel(eventId, data as EventItem);
      },
      ));
  }

  getEventsForUser(
    userId: string,
    options = { withJoiners: true },
  ): Observable<{
    events: GthEventItemModel[],
    joiners: { [key: string]: EventJoinerModel[] }
  }> {
    const query1 = this.collection$(
      (query) => query.where('creator', '==', userId),
      { idField: 'id' },
    );

    const query2 = this.collectionGroup$('joiner',
      (query) => query.where('player', '==', userId),
    ).pipe(
      switchMap((joiners) => {
        if (joiners.length === 0) {
          return of([]);
        }
        const parentDataObservables = joiners.map((joiner) => this.getParentData(joiner));
        return zip(...parentDataObservables);
      }),
    );

    return combineLatest([query1, query2]).pipe(
      map(([result1, result2]) => {
        // Filter out duplicate items based on ID
        const items = [...result1, ...result2];

        const filteredItems = items
          .filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.id === item.id
            )),
          );
        filteredItems.sort((a, b) => a.dateStart < b.dateStart ? -1 :
          a.dateStart > b.dateStart ? 1 : 0);
        return items.map((item) => new GthEventItemModel(item.id, item));
      }),

      switchMap((events) => {
        return this.getJoinersForEventList(events, options);
      }),
    );
  }

  getJoinersForEventList(events: GthEventItemModel[], options = { withJoiners: true }) {
    if (!events || events.length === 0) {
      return of({ events: [], joiners: {} });
    }
    if (!!options.withJoiners) {
      const joinersObservables = events.map((eventItem) => {
        return this.joinersService.getJoiners(eventItem.id).pipe(
          map((joiners) => {
            return { eventId: eventItem.id, joiners };
          }),
        );
      });
      return combineLatest(joinersObservables).pipe(
        map((results) => {
          const joinersObj = results.reduce((acc, { eventId, joiners }) => {
            acc[eventId] = joiners;
            return acc;
          }, {});
          return { events, joiners: joinersObj };
        }),
      );
    } else {
      return of({ events, joiners: {} });
    }
  }

  getParentData(data: DocumentChangeAction<DocumentData>) {
    const mainRef = data.payload.doc.ref;
    return from(mainRef.parent.parent.get()).pipe(map((doc) => {
      return {
        ...doc.data() as EventItem,
        id: doc.id,
      };
    }));
  }

  getEventsByLocation$(lat: number, lng: number) {
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    interface EventItemWithId extends EventItem {
      id: string;
    }
    const events$ = from(
      this.firestore
        .collection(DBUtil.EventItem)
        .get(),
    ).pipe(
      map((snapshot) => snapshot.docs.map((doc) => {
        const eventData = doc.data() as EventItemWithId;
        return { ...eventData, id: doc.id };
      })),
      shareReplay(1),
    );
    return events$.pipe(
      /** Filter events that within 200km of the specified location */
      map((events) => {
        return events.filter((event) => {
          if (!event?.location?.lat || !event?.location?.lng) return false;

          const distanceInKm = distanceBetween(
            [event.location.lat, event.location.lng],
            center,
          );
          const distanceInM = distanceInKm * 2000;
          return distanceInM <= radiusInM;
        });
      }),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((events) => events.map((event) => new GthEventItemModel(event.id, event))),
      switchMap((events) => {
        return this.getJoinersForEventList(events);
      }),
    );
  }

  async createEvent(eventItemModel: EventItemModel | EventItem) {
    const collectionRef = this.collection;

    const docRef = eventItemModel.id ?
      collectionRef.doc(eventItemModel.id) :
      collectionRef.doc();

    eventItemModel['ref'] = docRef.ref;

    await docRef.set(eventItemModel);

    return docRef;
  }

  async updateEvent(
    request: EventItem | UpdateEventItemRequest | EventItemModel,
  ) {
    const eventItemModel = this.mapper.toMap(request as unknown as EventItemModel);

    const doc = this.doc(request.id);

    const response: UpdateResponse = {
      success: true,
      joinerType: null,
    };

    /** Set flag for firebase function to sync with Stripe */
    eventItemModel['syncedToStripe'] = false;

    await doc.set(eventItemModel, { merge: true });

    return response;
  }

  async list(filter: EventItemListFilter): Promise<GthEventItemModel[]> {
    return await this.eventListFactory.get(filter)
      .then((events) => {
        return events.map((e) => new GthEventItemModel(e.id, e));
      });
  }

  async deleteEvent(request: EventItemDeleteRequest) {
    const doc = this.doc(request.eventId);

    return await doc.delete()
      .then(() => true)
      .catch((error: unknown) => {
        console.error(`Error deleting event: ${request.eventId}`, error);
        return false;
      });
  }

  async updatePageView(contract: UpdateEventPageViewContract) {
    if (!contract?.event) return Promise.resolve(false);

    /** Increment event page view count */
    const shardId = Math.floor(Math.random() * this.eventPageViewShards).toString();

    const dateFormat = new Date().toISOString().slice(0, 10);

    const shardRef = this.firestore.collection(DBUtil.EventItem).doc(contract.event.id)
      .collection(DBUtil.EventInsights).doc(dateFormat)
      .collection('shards').doc(shardId);

    return await shardRef.set(
      { views: firebase.firestore.FieldValue.increment(1) },
      { merge: true },
    ).then(() => true)
      .catch((error: unknown) => {
      console.error(`Error updating event page view: ${contract.event.id}`, error);
      return false;
    });
  }

  async getPageViews(contract: GetEventPageViewContract) {
    if (!contract?.event) return Promise.resolve(0);

    /** Consolidate all shard page views */
    let views = 0;

    for (let i = 0; i < this.eventPageViewShards; i++) {
      const todayDate = new Date();

      const date = contract?.date ?? todayDate;

      const dateFormat = date.toISOString().slice(0, 10);

      if (!contract.event.id) {
        return Promise.reject(new Error('No event id provided'));
      }

      const shardRef = this.doc(contract.event.id)
        .collection(DBUtil.EventInsights).doc(dateFormat)
        .collection(DBUtil.Shards).doc(i.toString());

      const shardSnap = await lastValueFrom(shardRef.get());

      if (!shardSnap.exists) continue;

      views = views + (await shardSnap.get('views'));
    }

    return Promise.resolve(views);
  }
}
